// this file is generated from the shared currencies tsv file, do not modify

interface CurrencyPair {
  pair: string;
  ccy1: string;
  ccy2: string;
  precision: number;
  isInverted: boolean;
}

export const currencies: Record<string, CurrencyPair> = {
  'AED/AUD': {
    pair: 'AED/AUD',
    ccy1: 'AED',
    ccy2: 'AUD',
    precision: 4,
    isInverted: true
  },
  'AED/CAD': {
    pair: 'AED/CAD',
    ccy1: 'AED',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true
  },
  'AED/CHF': {
    pair: 'AED/CHF',
    ccy1: 'AED',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'AED/CNH': {
    pair: 'AED/CNH',
    ccy1: 'AED',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false
  },
  'AED/EUR': {
    pair: 'AED/EUR',
    ccy1: 'AED',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'AED/GBP': {
    pair: 'AED/GBP',
    ccy1: 'AED',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'AED/HKD': {
    pair: 'AED/HKD',
    ccy1: 'AED',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false
  },
  'AED/JPY': {
    pair: 'AED/JPY',
    ccy1: 'AED',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false
  },
  'AED/NOK': {
    pair: 'AED/NOK',
    ccy1: 'AED',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false
  },
  'AED/PLN': {
    pair: 'AED/PLN',
    ccy1: 'AED',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false
  },
  'AED/SAR': {
    pair: 'AED/SAR',
    ccy1: 'AED',
    ccy2: 'SAR',
    precision: 5,
    isInverted: true
  },
  'AED/SEK': {
    pair: 'AED/SEK',
    ccy1: 'AED',
    ccy2: 'SEK',
    precision: 4,
    isInverted: false
  },
  'AED/SGD': {
    pair: 'AED/SGD',
    ccy1: 'AED',
    ccy2: 'SGD',
    precision: 5,
    isInverted: true
  },
  'AED/USD': {
    pair: 'AED/USD',
    ccy1: 'AED',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'AUD/AED': {
    pair: 'AUD/AED',
    ccy1: 'AUD',
    ccy2: 'AED',
    precision: 4,
    isInverted: false
  },
  'AUD/CAD': {
    pair: 'AUD/CAD',
    ccy1: 'AUD',
    ccy2: 'CAD',
    precision: 5,
    isInverted: false
  },
  'AUD/CHF': {
    pair: 'AUD/CHF',
    ccy1: 'AUD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false
  },
  'AUD/CNH': {
    pair: 'AUD/CNH',
    ccy1: 'AUD',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false
  },
  'AUD/CZK': {
    pair: 'AUD/CZK',
    ccy1: 'AUD',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false
  },
  'AUD/DKK': {
    pair: 'AUD/DKK',
    ccy1: 'AUD',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false
  },
  'AUD/EUR': {
    pair: 'AUD/EUR',
    ccy1: 'AUD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'AUD/GBP': {
    pair: 'AUD/GBP',
    ccy1: 'AUD',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'AUD/HKD': {
    pair: 'AUD/HKD',
    ccy1: 'AUD',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false
  },
  'AUD/HUF': {
    pair: 'AUD/HUF',
    ccy1: 'AUD',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false
  },
  'AUD/ILS': {
    pair: 'AUD/ILS',
    ccy1: 'AUD',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false
  },
  'AUD/JPY': {
    pair: 'AUD/JPY',
    ccy1: 'AUD',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false
  },
  'AUD/KES': {
    pair: 'AUD/KES',
    ccy1: 'AUD',
    ccy2: 'KES',
    precision: 4,
    isInverted: true
  },
  'AUD/MAD': {
    pair: 'AUD/MAD',
    ccy1: 'AUD',
    ccy2: 'MAD',
    precision: 4,
    isInverted: false
  },
  'AUD/MXN': {
    pair: 'AUD/MXN',
    ccy1: 'AUD',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false
  },
  'AUD/NOK': {
    pair: 'AUD/NOK',
    ccy1: 'AUD',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false
  },
  'AUD/NZD': {
    pair: 'AUD/NZD',
    ccy1: 'AUD',
    ccy2: 'NZD',
    precision: 5,
    isInverted: false
  },
  'AUD/PLN': {
    pair: 'AUD/PLN',
    ccy1: 'AUD',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false
  },
  'AUD/SEK': {
    pair: 'AUD/SEK',
    ccy1: 'AUD',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false
  },
  'AUD/SGD': {
    pair: 'AUD/SGD',
    ccy1: 'AUD',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false
  },
  'AUD/THB': {
    pair: 'AUD/THB',
    ccy1: 'AUD',
    ccy2: 'THB',
    precision: 5,
    isInverted: false
  },
  'AUD/TRY': {
    pair: 'AUD/TRY',
    ccy1: 'AUD',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false
  },
  'AUD/USD': {
    pair: 'AUD/USD',
    ccy1: 'AUD',
    ccy2: 'USD',
    precision: 5,
    isInverted: false
  },
  'AUD/ZAR': {
    pair: 'AUD/ZAR',
    ccy1: 'AUD',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false
  },
  'BGN/CHF': {
    pair: 'BGN/CHF',
    ccy1: 'BGN',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'BGN/EUR': {
    pair: 'BGN/EUR',
    ccy1: 'BGN',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'BGN/GBP': {
    pair: 'BGN/GBP',
    ccy1: 'BGN',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'BGN/JPY': {
    pair: 'BGN/JPY',
    ccy1: 'BGN',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true
  },
  'BGN/PLN': {
    pair: 'BGN/PLN',
    ccy1: 'BGN',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false
  },
  'BGN/RON': {
    pair: 'BGN/RON',
    ccy1: 'BGN',
    ccy2: 'RON',
    precision: 5,
    isInverted: false
  },
  'BHD/CAD': {
    pair: 'BHD/CAD',
    ccy1: 'BHD',
    ccy2: 'CAD',
    precision: 6,
    isInverted: true
  },
  'BHD/USD': {
    pair: 'BHD/USD',
    ccy1: 'BHD',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'CAD/AED': {
    pair: 'CAD/AED',
    ccy1: 'CAD',
    ccy2: 'AED',
    precision: 4,
    isInverted: false
  },
  'CAD/AUD': {
    pair: 'CAD/AUD',
    ccy1: 'CAD',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true
  },
  'CAD/BGN': {
    pair: 'CAD/BGN',
    ccy1: 'CAD',
    ccy2: 'BGN',
    precision: 4,
    isInverted: false
  },
  'CAD/BHD': {
    pair: 'CAD/BHD',
    ccy1: 'CAD',
    ccy2: 'BHD',
    precision: 6,
    isInverted: false
  },
  'CAD/CHF': {
    pair: 'CAD/CHF',
    ccy1: 'CAD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false
  },
  'CAD/CNH': {
    pair: 'CAD/CNH',
    ccy1: 'CAD',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false
  },
  'CAD/CZK': {
    pair: 'CAD/CZK',
    ccy1: 'CAD',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false
  },
  'CAD/DKK': {
    pair: 'CAD/DKK',
    ccy1: 'CAD',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false
  },
  'CAD/EUR': {
    pair: 'CAD/EUR',
    ccy1: 'CAD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'CAD/GBP': {
    pair: 'CAD/GBP',
    ccy1: 'CAD',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'CAD/HKD': {
    pair: 'CAD/HKD',
    ccy1: 'CAD',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false
  },
  'CAD/HUF': {
    pair: 'CAD/HUF',
    ccy1: 'CAD',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false
  },
  'CAD/ILS': {
    pair: 'CAD/ILS',
    ccy1: 'CAD',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false
  },
  'CAD/JPY': {
    pair: 'CAD/JPY',
    ccy1: 'CAD',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false
  },
  'CAD/KWD': {
    pair: 'CAD/KWD',
    ccy1: 'CAD',
    ccy2: 'KWD',
    precision: 6,
    isInverted: false
  },
  'CAD/QAR': {
    pair: 'CAD/QAR',
    ccy1: 'CAD',
    ccy2: 'QAR',
    precision: 4,
    isInverted: false
  },
  'CAD/MXN': {
    pair: 'CAD/MXN',
    ccy1: 'CAD',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false
  },
  'CAD/NOK': {
    pair: 'CAD/NOK',
    ccy1: 'CAD',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false
  },
  'CAD/NZD': {
    pair: 'CAD/NZD',
    ccy1: 'CAD',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true
  },
  'CAD/PLN': {
    pair: 'CAD/PLN',
    ccy1: 'CAD',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false
  },
  'CAD/RON': {
    pair: 'CAD/RON',
    ccy1: 'CAD',
    ccy2: 'RON',
    precision: 4,
    isInverted: false
  },
  'CAD/SAR': {
    pair: 'CAD/SAR',
    ccy1: 'CAD',
    ccy2: 'SAR',
    precision: 5,
    isInverted: false
  },
  'CAD/SEK': {
    pair: 'CAD/SEK',
    ccy1: 'CAD',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false
  },
  'CAD/SGD': {
    pair: 'CAD/SGD',
    ccy1: 'CAD',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false
  },
  'CAD/THB': {
    pair: 'CAD/THB',
    ccy1: 'CAD',
    ccy2: 'THB',
    precision: 5,
    isInverted: false
  },
  'CAD/TRY': {
    pair: 'CAD/TRY',
    ccy1: 'CAD',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false
  },
  'CAD/USD': {
    pair: 'CAD/USD',
    ccy1: 'CAD',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'CAD/ZAR': {
    pair: 'CAD/ZAR',
    ccy1: 'CAD',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false
  },
  'CHF/AED': {
    pair: 'CHF/AED',
    ccy1: 'CHF',
    ccy2: 'AED',
    precision: 5,
    isInverted: false
  },
  'CHF/AUD': {
    pair: 'CHF/AUD',
    ccy1: 'CHF',
    ccy2: 'AUD',
    precision: 7,
    isInverted: true
  },
  'CHF/BGN': {
    pair: 'CHF/BGN',
    ccy1: 'CHF',
    ccy2: 'BGN',
    precision: 5,
    isInverted: false
  },
  'CHF/CAD': {
    pair: 'CHF/CAD',
    ccy1: 'CHF',
    ccy2: 'CAD',
    precision: 7,
    isInverted: true
  },
  'CHF/CNH': {
    pair: 'CHF/CNH',
    ccy1: 'CHF',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false
  },
  'CHF/CZK': {
    pair: 'CHF/CZK',
    ccy1: 'CHF',
    ccy2: 'CZK',
    precision: 4,
    isInverted: false
  },
  'CHF/DKK': {
    pair: 'CHF/DKK',
    ccy1: 'CHF',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false
  },
  'CHF/EUR': {
    pair: 'CHF/EUR',
    ccy1: 'CHF',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'CHF/GBP': {
    pair: 'CHF/GBP',
    ccy1: 'CHF',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'CHF/HKD': {
    pair: 'CHF/HKD',
    ccy1: 'CHF',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false
  },
  'CHF/HRK': {
    pair: 'CHF/HRK',
    ccy1: 'CHF',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false
  },
  'CHF/HUF': {
    pair: 'CHF/HUF',
    ccy1: 'CHF',
    ccy2: 'HUF',
    precision: 3,
    isInverted: false
  },
  'CHF/ILS': {
    pair: 'CHF/ILS',
    ccy1: 'CHF',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false
  },
  'CHF/JPY': {
    pair: 'CHF/JPY',
    ccy1: 'CHF',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false
  },
  'CHF/KWD': {
    pair: 'CHF/KWD',
    ccy1: 'CHF',
    ccy2: 'KWD',
    precision: 5,
    isInverted: false
  },
  'CHF/MAD': {
    pair: 'CHF/MAD',
    ccy1: 'CHF',
    ccy2: 'MAD',
    precision: 5,
    isInverted: false
  },
  'CHF/MXN': {
    pair: 'CHF/MXN',
    ccy1: 'CHF',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false
  },
  'CHF/NOK': {
    pair: 'CHF/NOK',
    ccy1: 'CHF',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false
  },
  'CHF/NZD': {
    pair: 'CHF/NZD',
    ccy1: 'CHF',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true
  },
  'CHF/PLN': {
    pair: 'CHF/PLN',
    ccy1: 'CHF',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false
  },
  'CHF/QAR': {
    pair: 'CHF/QAR',
    ccy1: 'CHF',
    ccy2: 'QAR',
    precision: 5,
    isInverted: false
  },
  'CHF/RON': {
    pair: 'CHF/RON',
    ccy1: 'CHF',
    ccy2: 'RON',
    precision: 5,
    isInverted: false
  },
  'CHF/RSD': {
    pair: 'CHF/RSD',
    ccy1: 'CHF',
    ccy2: 'RSD',
    precision: 5,
    isInverted: false
  },
  'CHF/SAR': {
    pair: 'CHF/SAR',
    ccy1: 'CHF',
    ccy2: 'SAR',
    precision: 5,
    isInverted: true
  },
  'CHF/SEK': {
    pair: 'CHF/SEK',
    ccy1: 'CHF',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false
  },
  'CHF/SGD': {
    pair: 'CHF/SGD',
    ccy1: 'CHF',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false
  },
  'CHF/THB': {
    pair: 'CHF/THB',
    ccy1: 'CHF',
    ccy2: 'THB',
    precision: 5,
    isInverted: false
  },
  'CHF/TND': {
    pair: 'CHF/TND',
    ccy1: 'CHF',
    ccy2: 'TND',
    precision: 5,
    isInverted: true
  },
  'CHF/TRY': {
    pair: 'CHF/TRY',
    ccy1: 'CHF',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false
  },
  'CHF/USD': {
    pair: 'CHF/USD',
    ccy1: 'CHF',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'CHF/ZAR': {
    pair: 'CHF/ZAR',
    ccy1: 'CHF',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false
  },
  'CNH/AED': {
    pair: 'CNH/AED',
    ccy1: 'CNH',
    ccy2: 'AED',
    precision: 5,
    isInverted: true
  },
  'CNH/AUD': {
    pair: 'CNH/AUD',
    ccy1: 'CNH',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true
  },
  'CNH/CAD': {
    pair: 'CNH/CAD',
    ccy1: 'CNH',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true
  },
  'CNH/CHF': {
    pair: 'CNH/CHF',
    ccy1: 'CNH',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'CNH/CZK': {
    pair: 'CNH/CZK',
    ccy1: 'CNH',
    ccy2: 'CZK',
    precision: 3,
    isInverted: false
  },
  'CNH/DKK': {
    pair: 'CNH/DKK',
    ccy1: 'CNH',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true
  },
  'CNH/EUR': {
    pair: 'CNH/EUR',
    ccy1: 'CNH',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'CNH/GBP': {
    pair: 'CNH/GBP',
    ccy1: 'CNH',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'CNH/HKD': {
    pair: 'CNH/HKD',
    ccy1: 'CNH',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false
  },
  'CNH/HUF': {
    pair: 'CNH/HUF',
    ccy1: 'CNH',
    ccy2: 'HUF',
    precision: 2,
    isInverted: false
  },
  'CNH/JPY': {
    pair: 'CNH/JPY',
    ccy1: 'CNH',
    ccy2: 'JPY',
    precision: 4,
    isInverted: false
  },
  'CNH/MXN': {
    pair: 'CNH/MXN',
    ccy1: 'CNH',
    ccy2: 'MXN',
    precision: 4,
    isInverted: false
  },
  'CNH/NOK': {
    pair: 'CNH/NOK',
    ccy1: 'CNH',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true
  },
  'CNH/NZD': {
    pair: 'CNH/NZD',
    ccy1: 'CNH',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true
  },
  'CNH/PLN': {
    pair: 'CNH/PLN',
    ccy1: 'CNH',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true
  },
  'CNH/SEK': {
    pair: 'CNH/SEK',
    ccy1: 'CNH',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true
  },
  'CNH/SGD': {
    pair: 'CNH/SGD',
    ccy1: 'CNH',
    ccy2: 'SGD',
    precision: 5,
    isInverted: true
  },
  'CNH/THB': {
    pair: 'CNH/THB',
    ccy1: 'CNH',
    ccy2: 'THB',
    precision: 4,
    isInverted: true
  },
  'CNH/USD': {
    pair: 'CNH/USD',
    ccy1: 'CNH',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'CNH/ZAR': {
    pair: 'CNH/ZAR',
    ccy1: 'CNH',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: true
  },
  'CZK/AUD': {
    pair: 'CZK/AUD',
    ccy1: 'CZK',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true
  },
  'CZK/CHF': {
    pair: 'CZK/CHF',
    ccy1: 'CZK',
    ccy2: 'CHF',
    precision: 7,
    isInverted: true
  },
  'CZK/DKK': {
    pair: 'CZK/DKK',
    ccy1: 'CZK',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true
  },
  'CZK/EUR': {
    pair: 'CZK/EUR',
    ccy1: 'CZK',
    ccy2: 'EUR',
    precision: 6,
    isInverted: true
  },
  'CZK/GBP': {
    pair: 'CZK/GBP',
    ccy1: 'CZK',
    ccy2: 'GBP',
    precision: 6,
    isInverted: true
  },
  'CZK/HKD': {
    pair: 'CZK/HKD',
    ccy1: 'CZK',
    ccy2: 'HKD',
    precision: 5,
    isInverted: true
  },
  'CZK/HRK': {
    pair: 'CZK/HRK',
    ccy1: 'CZK',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false
  },
  'CZK/HUF': {
    pair: 'CZK/HUF',
    ccy1: 'CZK',
    ccy2: 'HUF',
    precision: 4,
    isInverted: false
  },
  'CZK/JPY': {
    pair: 'CZK/JPY',
    ccy1: 'CZK',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false
  },
  'CZK/NOK': {
    pair: 'CZK/NOK',
    ccy1: 'CZK',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true
  },
  'CZK/PLN': {
    pair: 'CZK/PLN',
    ccy1: 'CZK',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false
  },
  'CZK/RON': {
    pair: 'CZK/RON',
    ccy1: 'CZK',
    ccy2: 'RON',
    precision: 5,
    isInverted: false
  },
  'CZK/SEK': {
    pair: 'CZK/SEK',
    ccy1: 'CZK',
    ccy2: 'SEK',
    precision: 6,
    isInverted: false
  },
  'CZK/SGD': {
    pair: 'CZK/SGD',
    ccy1: 'CZK',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false
  },
  'CZK/USD': {
    pair: 'CZK/USD',
    ccy1: 'CZK',
    ccy2: 'USD',
    precision: 6,
    isInverted: true
  },
  'CZK/ZAR': {
    pair: 'CZK/ZAR',
    ccy1: 'CZK',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false
  },
  'DKK/AUD': {
    pair: 'DKK/AUD',
    ccy1: 'DKK',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true
  },
  'DKK/CAD': {
    pair: 'DKK/CAD',
    ccy1: 'DKK',
    ccy2: 'CAD',
    precision: 7,
    isInverted: true
  },
  'DKK/CHF': {
    pair: 'DKK/CHF',
    ccy1: 'DKK',
    ccy2: 'CHF',
    precision: 7,
    isInverted: true
  },
  'DKK/CNH': {
    pair: 'DKK/CNH',
    ccy1: 'DKK',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false
  },
  'DKK/CZK': {
    pair: 'DKK/CZK',
    ccy1: 'DKK',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false
  },
  'DKK/EUR': {
    pair: 'DKK/EUR',
    ccy1: 'DKK',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true
  },
  'DKK/GBP': {
    pair: 'DKK/GBP',
    ccy1: 'DKK',
    ccy2: 'GBP',
    precision: 7,
    isInverted: true
  },
  'DKK/HKD': {
    pair: 'DKK/HKD',
    ccy1: 'DKK',
    ccy2: 'HKD',
    precision: 6,
    isInverted: false
  },
  'DKK/HUF': {
    pair: 'DKK/HUF',
    ccy1: 'DKK',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false
  },
  'DKK/JPY': {
    pair: 'DKK/JPY',
    ccy1: 'DKK',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false
  },
  'DKK/NOK': {
    pair: 'DKK/NOK',
    ccy1: 'DKK',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true
  },
  'DKK/NZD': {
    pair: 'DKK/NZD',
    ccy1: 'DKK',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true
  },
  'DKK/PLN': {
    pair: 'DKK/PLN',
    ccy1: 'DKK',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true
  },
  'DKK/RON': {
    pair: 'DKK/RON',
    ccy1: 'DKK',
    ccy2: 'RON',
    precision: 5,
    isInverted: false
  },
  'DKK/SEK': {
    pair: 'DKK/SEK',
    ccy1: 'DKK',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false
  },
  'DKK/SGD': {
    pair: 'DKK/SGD',
    ccy1: 'DKK',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false
  },
  'DKK/TRY': {
    pair: 'DKK/TRY',
    ccy1: 'DKK',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false
  },
  'DKK/USD': {
    pair: 'DKK/USD',
    ccy1: 'DKK',
    ccy2: 'USD',
    precision: 7,
    isInverted: true
  },
  'DZD/EUR': {
    pair: 'DZD/EUR',
    ccy1: 'DZD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'DZD/USD': {
    pair: 'DZD/USD',
    ccy1: 'DZD',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'EUR/AED': {
    pair: 'EUR/AED',
    ccy1: 'EUR',
    ccy2: 'AED',
    precision: 5,
    isInverted: false
  },
  'EUR/AUD': {
    pair: 'EUR/AUD',
    ccy1: 'EUR',
    ccy2: 'AUD',
    precision: 5,
    isInverted: false
  },
  'EUR/BGN': {
    pair: 'EUR/BGN',
    ccy1: 'EUR',
    ccy2: 'BGN',
    precision: 5,
    isInverted: false
  },
  'EUR/BHD': {
    pair: 'EUR/BHD',
    ccy1: 'EUR',
    ccy2: 'BHD',
    precision: 6,
    isInverted: false
  },
  'EUR/CAD': {
    pair: 'EUR/CAD',
    ccy1: 'EUR',
    ccy2: 'CAD',
    precision: 5,
    isInverted: false
  },
  'EUR/CHF': {
    pair: 'EUR/CHF',
    ccy1: 'EUR',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false
  },
  'EUR/CNH': {
    pair: 'EUR/CNH',
    ccy1: 'EUR',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false
  },
  'EUR/CZK': {
    pair: 'EUR/CZK',
    ccy1: 'EUR',
    ccy2: 'CZK',
    precision: 4,
    isInverted: false
  },
  'EUR/DKK': {
    pair: 'EUR/DKK',
    ccy1: 'EUR',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false
  },
  'EUR/DZD': {
    pair: 'EUR/DZD',
    ccy1: 'EUR',
    ccy2: 'DZD',
    precision: 5,
    isInverted: false
  },
  'EUR/GBP': {
    pair: 'EUR/GBP',
    ccy1: 'EUR',
    ccy2: 'GBP',
    precision: 5,
    isInverted: false
  },
  'EUR/HKD': {
    pair: 'EUR/HKD',
    ccy1: 'EUR',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false
  },
  'EUR/HRK': {
    pair: 'EUR/HRK',
    ccy1: 'EUR',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false
  },
  'EUR/HUF': {
    pair: 'EUR/HUF',
    ccy1: 'EUR',
    ccy2: 'HUF',
    precision: 3,
    isInverted: false
  },
  'EUR/ILS': {
    pair: 'EUR/ILS',
    ccy1: 'EUR',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false
  },
  'EUR/ISK': {
    pair: 'EUR/ISK',
    ccy1: 'EUR',
    ccy2: 'ISK',
    precision: 3,
    isInverted: false
  },
  'EUR/JOD': {
    pair: 'EUR/JOD',
    ccy1: 'EUR',
    ccy2: 'JOD',
    precision: 5,
    isInverted: false
  },
  'EUR/JPY': {
    pair: 'EUR/JPY',
    ccy1: 'EUR',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false
  },
  'EUR/KWD': {
    pair: 'EUR/KWD',
    ccy1: 'EUR',
    ccy2: 'KWD',
    precision: 6,
    isInverted: false
  },
  'EUR/MAD': {
    pair: 'EUR/MAD',
    ccy1: 'EUR',
    ccy2: 'MAD',
    precision: 5,
    isInverted: false
  },
  'EUR/MUR': {
    pair: 'EUR/MUR',
    ccy1: 'EUR',
    ccy2: 'MUR',
    precision: 3,
    isInverted: false
  },
  'EUR/MXN': {
    pair: 'EUR/MXN',
    ccy1: 'EUR',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false
  },
  'EUR/MZN': {
    pair: 'EUR/MZN',
    ccy1: 'EUR',
    ccy2: 'MZN',
    precision: 2,
    isInverted: false
  },
  'EUR/NOK': {
    pair: 'EUR/NOK',
    ccy1: 'EUR',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false
  },
  'EUR/NZD': {
    pair: 'EUR/NZD',
    ccy1: 'EUR',
    ccy2: 'NZD',
    precision: 5,
    isInverted: false
  },
  'EUR/OMR': {
    pair: 'EUR/OMR',
    ccy1: 'EUR',
    ccy2: 'OMR',
    precision: 5,
    isInverted: false
  },
  'EUR/PLN': {
    pair: 'EUR/PLN',
    ccy1: 'EUR',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false
  },
  'EUR/QAR': {
    pair: 'EUR/QAR',
    ccy1: 'EUR',
    ccy2: 'QAR',
    precision: 5,
    isInverted: false
  },
  'EUR/RON': {
    pair: 'EUR/RON',
    ccy1: 'EUR',
    ccy2: 'RON',
    precision: 5,
    isInverted: false
  },
  'EUR/RSD': {
    pair: 'EUR/RSD',
    ccy1: 'EUR',
    ccy2: 'RSD',
    precision: 4,
    isInverted: false
  },
  'EUR/SAR': {
    pair: 'EUR/SAR',
    ccy1: 'EUR',
    ccy2: 'SAR',
    precision: 5,
    isInverted: false
  },
  'EUR/SEK': {
    pair: 'EUR/SEK',
    ccy1: 'EUR',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false
  },
  'EUR/SGD': {
    pair: 'EUR/SGD',
    ccy1: 'EUR',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false
  },
  'EUR/THB': {
    pair: 'EUR/THB',
    ccy1: 'EUR',
    ccy2: 'THB',
    precision: 3,
    isInverted: false
  },
  'EUR/TND': {
    pair: 'EUR/TND',
    ccy1: 'EUR',
    ccy2: 'TND',
    precision: 5,
    isInverted: false
  },
  'EUR/TRY': {
    pair: 'EUR/TRY',
    ccy1: 'EUR',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false
  },
  'EUR/USD': {
    pair: 'EUR/USD',
    ccy1: 'EUR',
    ccy2: 'USD',
    precision: 5,
    isInverted: false
  },
  'EUR/XAF': {
    pair: 'EUR/XAF',
    ccy1: 'EUR',
    ccy2: 'XAF',
    precision: 4,
    isInverted: false
  },
  'EUR/XOF': {
    pair: 'EUR/XOF',
    ccy1: 'EUR',
    ccy2: 'XOF',
    precision: 3,
    isInverted: false
  },
  'EUR/ZAR': {
    pair: 'EUR/ZAR',
    ccy1: 'EUR',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false
  },
  'GBP/AED': {
    pair: 'GBP/AED',
    ccy1: 'GBP',
    ccy2: 'AED',
    precision: 5,
    isInverted: false
  },
  'GBP/AUD': {
    pair: 'GBP/AUD',
    ccy1: 'GBP',
    ccy2: 'AUD',
    precision: 5,
    isInverted: false
  },
  'GBP/BGN': {
    pair: 'GBP/BGN',
    ccy1: 'GBP',
    ccy2: 'BGN',
    precision: 5,
    isInverted: false
  },
  'GBP/CAD': {
    pair: 'GBP/CAD',
    ccy1: 'GBP',
    ccy2: 'CAD',
    precision: 5,
    isInverted: false
  },
  'GBP/CHF': {
    pair: 'GBP/CHF',
    ccy1: 'GBP',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false
  },
  'GBP/CNH': {
    pair: 'GBP/CNH',
    ccy1: 'GBP',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false
  },
  'GBP/CZK': {
    pair: 'GBP/CZK',
    ccy1: 'GBP',
    ccy2: 'CZK',
    precision: 4,
    isInverted: false
  },
  'GBP/DKK': {
    pair: 'GBP/DKK',
    ccy1: 'GBP',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false
  },
  'GBP/EUR': {
    pair: 'GBP/EUR',
    ccy1: 'GBP',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'GBP/HKD': {
    pair: 'GBP/HKD',
    ccy1: 'GBP',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false
  },
  'GBP/HRK': {
    pair: 'GBP/HRK',
    ccy1: 'GBP',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false
  },
  'GBP/HUF': {
    pair: 'GBP/HUF',
    ccy1: 'GBP',
    ccy2: 'HUF',
    precision: 3,
    isInverted: false
  },
  'GBP/ILS': {
    pair: 'GBP/ILS',
    ccy1: 'GBP',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false
  },
  'GBP/JPY': {
    pair: 'GBP/JPY',
    ccy1: 'GBP',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false
  },
  'GBP/KES': {
    pair: 'GBP/KES',
    ccy1: 'GBP',
    ccy2: 'KES',
    precision: 2,
    isInverted: false
  },
  'GBP/KWD': {
    pair: 'GBP/KWD',
    ccy1: 'GBP',
    ccy2: 'KWD',
    precision: 6,
    isInverted: false
  },
  'GBP/MAD': {
    pair: 'GBP/MAD',
    ccy1: 'GBP',
    ccy2: 'MAD',
    precision: 5,
    isInverted: false
  },
  'GBP/MXN': {
    pair: 'GBP/MXN',
    ccy1: 'GBP',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false
  },
  'GBP/NOK': {
    pair: 'GBP/NOK',
    ccy1: 'GBP',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false
  },
  'GBP/NZD': {
    pair: 'GBP/NZD',
    ccy1: 'GBP',
    ccy2: 'NZD',
    precision: 5,
    isInverted: false
  },
  'GBP/PLN': {
    pair: 'GBP/PLN',
    ccy1: 'GBP',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false
  },
  'GBP/QAR': {
    pair: 'GBP/QAR',
    ccy1: 'GBP',
    ccy2: 'QAR',
    precision: 4,
    isInverted: false
  },
  'GBP/RON': {
    pair: 'GBP/RON',
    ccy1: 'GBP',
    ccy2: 'RON',
    precision: 5,
    isInverted: false
  },
  'GBP/SAR': {
    pair: 'GBP/SAR',
    ccy1: 'GBP',
    ccy2: 'SAR',
    precision: 5,
    isInverted: false
  },
  'GBP/SEK': {
    pair: 'GBP/SEK',
    ccy1: 'GBP',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false
  },
  'GBP/SGD': {
    pair: 'GBP/SGD',
    ccy1: 'GBP',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false
  },
  'GBP/THB': {
    pair: 'GBP/THB',
    ccy1: 'GBP',
    ccy2: 'THB',
    precision: 5,
    isInverted: false
  },
  'GBP/TND': {
    pair: 'GBP/TND',
    ccy1: 'GBP',
    ccy2: 'TND',
    precision: 5,
    isInverted: false
  },
  'GBP/TRY': {
    pair: 'GBP/TRY',
    ccy1: 'GBP',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false
  },
  'GBP/USD': {
    pair: 'GBP/USD',
    ccy1: 'GBP',
    ccy2: 'USD',
    precision: 5,
    isInverted: false
  },
  'GBP/ZAR': {
    pair: 'GBP/ZAR',
    ccy1: 'GBP',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false
  },
  'GHS/CHF': {
    pair: 'GHS/CHF',
    ccy1: 'GHS',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'GHS/EUR': {
    pair: 'GHS/EUR',
    ccy1: 'GHS',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'GHS/GBP': {
    pair: 'GHS/GBP',
    ccy1: 'GHS',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'GHS/JPY': {
    pair: 'GHS/JPY',
    ccy1: 'GHS',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true
  },
  'GHS/USD': {
    pair: 'GHS/USD',
    ccy1: 'GHS',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'HKD/AOA': {
    pair: 'HKD/AOA',
    ccy1: 'HKD',
    ccy2: 'AOA',
    precision: 4,
    isInverted: true
  },
  'HKD/AUD': {
    pair: 'HKD/AUD',
    ccy1: 'HKD',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true
  },
  'HKD/CAD': {
    pair: 'HKD/CAD',
    ccy1: 'HKD',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true
  },
  'HKD/CHF': {
    pair: 'HKD/CHF',
    ccy1: 'HKD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'HKD/CNH': {
    pair: 'HKD/CNH',
    ccy1: 'HKD',
    ccy2: 'CNH',
    precision: 5,
    isInverted: true
  },
  'HKD/CZK': {
    pair: 'HKD/CZK',
    ccy1: 'HKD',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false
  },
  'HKD/DKK': {
    pair: 'HKD/DKK',
    ccy1: 'HKD',
    ccy2: 'DKK',
    precision: 4,
    isInverted: false
  },
  'HKD/EUR': {
    pair: 'HKD/EUR',
    ccy1: 'HKD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'HKD/GBP': {
    pair: 'HKD/GBP',
    ccy1: 'HKD',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'HKD/HUF': {
    pair: 'HKD/HUF',
    ccy1: 'HKD',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false
  },
  'HKD/JPY': {
    pair: 'HKD/JPY',
    ccy1: 'HKD',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false
  },
  'HKD/MXN': {
    pair: 'HKD/MXN',
    ccy1: 'HKD',
    ccy2: 'MXN',
    precision: 4,
    isInverted: true
  },
  'HKD/KWD': {
    pair: 'HKD/KWD',
    ccy1: 'HKD',
    ccy2: 'KWD',
    precision: 5,
    isInverted: false
  },
  'HKD/NOK': {
    pair: 'HKD/NOK',
    ccy1: 'HKD',
    ccy2: 'NOK',
    precision: 4,
    isInverted: false
  },
  'HKD/NZD': {
    pair: 'HKD/NZD',
    ccy1: 'HKD',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true
  },
  'HKD/PLN': {
    pair: 'HKD/PLN',
    ccy1: 'HKD',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true
  },
  'HKD/SEK': {
    pair: 'HKD/SEK',
    ccy1: 'HKD',
    ccy2: 'SEK',
    precision: 4,
    isInverted: false
  },
  'HKD/SGD': {
    pair: 'HKD/SGD',
    ccy1: 'HKD',
    ccy2: 'SGD',
    precision: 5,
    isInverted: true
  },
  'HKD/THB': {
    pair: 'HKD/THB',
    ccy1: 'HKD',
    ccy2: 'THB',
    precision: 5,
    isInverted: false
  },
  'HKD/USD': {
    pair: 'HKD/USD',
    ccy1: 'HKD',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'HRK/CHF': {
    pair: 'HRK/CHF',
    ccy1: 'HRK',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'HRK/CZK': {
    pair: 'HRK/CZK',
    ccy1: 'HRK',
    ccy2: 'CZK',
    precision: 5,
    isInverted: true
  },
  'HRK/EUR': {
    pair: 'HRK/EUR',
    ccy1: 'HRK',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'HRK/GBP': {
    pair: 'HRK/GBP',
    ccy1: 'HRK',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'HRK/HUF': {
    pair: 'HRK/HUF',
    ccy1: 'HRK',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true
  },
  'HRK/JPY': {
    pair: 'HRK/JPY',
    ccy1: 'HRK',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true
  },
  'HRK/PLN': {
    pair: 'HRK/PLN',
    ccy1: 'HRK',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true
  },
  'HRK/USD': {
    pair: 'HRK/USD',
    ccy1: 'HRK',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'HUF/AUD': {
    pair: 'HUF/AUD',
    ccy1: 'HUF',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true
  },
  'HUF/CAD': {
    pair: 'HUF/CAD',
    ccy1: 'HUF',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true
  },
  'HUF/CHF': {
    pair: 'HUF/CHF',
    ccy1: 'HUF',
    ccy2: 'CHF',
    precision: 7,
    isInverted: true
  },
  'HUF/DKK': {
    pair: 'HUF/DKK',
    ccy1: 'HUF',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true
  },
  'HUF/EUR': {
    pair: 'HUF/EUR',
    ccy1: 'HUF',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true
  },
  'HUF/GBP': {
    pair: 'HUF/GBP',
    ccy1: 'HUF',
    ccy2: 'GBP',
    precision: 7,
    isInverted: true
  },
  'HUF/HKD': {
    pair: 'HUF/HKD',
    ccy1: 'HUF',
    ccy2: 'HKD',
    precision: 5,
    isInverted: true
  },
  'HUF/HRK': {
    pair: 'HUF/HRK',
    ccy1: 'HUF',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false
  },
  'HUF/ILS': {
    pair: 'HUF/ILS',
    ccy1: 'HUF',
    ccy2: 'ILS',
    precision: 5,
    isInverted: true
  },
  'HUF/JPY': {
    pair: 'HUF/JPY',
    ccy1: 'HUF',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false
  },
  'HUF/NOK': {
    pair: 'HUF/NOK',
    ccy1: 'HUF',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true
  },
  'HUF/PLN': {
    pair: 'HUF/PLN',
    ccy1: 'HUF',
    ccy2: 'PLN',
    precision: 7,
    isInverted: true
  },
  'HUF/RON': {
    pair: 'HUF/RON',
    ccy1: 'HUF',
    ccy2: 'RON',
    precision: 5,
    isInverted: false
  },
  'HUF/SEK': {
    pair: 'HUF/SEK',
    ccy1: 'HUF',
    ccy2: 'SEK',
    precision: 7,
    isInverted: true
  },
  'HUF/SGD': {
    pair: 'HUF/SGD',
    ccy1: 'HUF',
    ccy2: 'SGD',
    precision: 5,
    isInverted: true
  },
  'HUF/TRY': {
    pair: 'HUF/TRY',
    ccy1: 'HUF',
    ccy2: 'TRY',
    precision: 7,
    isInverted: true
  },
  'HUF/USD': {
    pair: 'HUF/USD',
    ccy1: 'HUF',
    ccy2: 'USD',
    precision: 7,
    isInverted: true
  },
  'HUF/ZAR': {
    pair: 'HUF/ZAR',
    ccy1: 'HUF',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false
  },
  'ILS/CAD': {
    pair: 'ILS/CAD',
    ccy1: 'ILS',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true
  },
  'ILS/CHF': {
    pair: 'ILS/CHF',
    ccy1: 'ILS',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'ILS/EUR': {
    pair: 'ILS/EUR',
    ccy1: 'ILS',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'ILS/GBP': {
    pair: 'ILS/GBP',
    ccy1: 'ILS',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'ILS/HUF': {
    pair: 'ILS/HUF',
    ccy1: 'ILS',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false
  },
  'ILS/JPY': {
    pair: 'ILS/JPY',
    ccy1: 'ILS',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false
  },
  'ILS/NZD': {
    pair: 'ILS/NZD',
    ccy1: 'ILS',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true
  },
  'ILS/PLN': {
    pair: 'ILS/PLN',
    ccy1: 'ILS',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true
  },
  'ILS/USD': {
    pair: 'ILS/USD',
    ccy1: 'ILS',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'JPY/AED': {
    pair: 'JPY/AED',
    ccy1: 'JPY',
    ccy2: 'AED',
    precision: 5,
    isInverted: true
  },
  'JPY/AUD': {
    pair: 'JPY/AUD',
    ccy1: 'JPY',
    ccy2: 'AUD',
    precision: 7,
    isInverted: true
  },
  'JPY/BGN': {
    pair: 'JPY/BGN',
    ccy1: 'JPY',
    ccy2: 'BGN',
    precision: 5,
    isInverted: false
  },
  'JPY/CAD': {
    pair: 'JPY/CAD',
    ccy1: 'JPY',
    ccy2: 'CAD',
    precision: 7,
    isInverted: true
  },
  'JPY/CHF': {
    pair: 'JPY/CHF',
    ccy1: 'JPY',
    ccy2: 'CHF',
    precision: 7,
    isInverted: true
  },
  'JPY/CNH': {
    pair: 'JPY/CNH',
    ccy1: 'JPY',
    ccy2: 'CNH',
    precision: 7,
    isInverted: true
  },
  'JPY/CZK': {
    pair: 'JPY/CZK',
    ccy1: 'JPY',
    ccy2: 'CZK',
    precision: 5,
    isInverted: true
  },
  'JPY/DKK': {
    pair: 'JPY/DKK',
    ccy1: 'JPY',
    ccy2: 'DKK',
    precision: 7,
    isInverted: true
  },
  'JPY/EUR': {
    pair: 'JPY/EUR',
    ccy1: 'JPY',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true
  },
  'JPY/GBP': {
    pair: 'JPY/GBP',
    ccy1: 'JPY',
    ccy2: 'GBP',
    precision: 7,
    isInverted: true
  },
  'JPY/HKD': {
    pair: 'JPY/HKD',
    ccy1: 'JPY',
    ccy2: 'HKD',
    precision: 5,
    isInverted: true
  },
  'JPY/HRK': {
    pair: 'JPY/HRK',
    ccy1: 'JPY',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false
  },
  'JPY/HUF': {
    pair: 'JPY/HUF',
    ccy1: 'JPY',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true
  },
  'JPY/ILS': {
    pair: 'JPY/ILS',
    ccy1: 'JPY',
    ccy2: 'ILS',
    precision: 5,
    isInverted: true
  },
  'JPY/KES': {
    pair: 'JPY/KES',
    ccy1: 'JPY',
    ccy2: 'KES',
    precision: 5,
    isInverted: false
  },
  'JPY/KWD': {
    pair: 'JPY/KWD',
    ccy1: 'JPY',
    ccy2: 'KWD',
    precision: 5,
    isInverted: false
  },
  'JPY/MAD': {
    pair: 'JPY/MAD',
    ccy1: 'JPY',
    ccy2: 'MAD',
    precision: 5,
    isInverted: false
  },
  'JPY/MXN': {
    pair: 'JPY/MXN',
    ccy1: 'JPY',
    ccy2: 'MXN',
    precision: 5,
    isInverted: true
  },
  'JPY/NOK': {
    pair: 'JPY/NOK',
    ccy1: 'JPY',
    ccy2: 'NOK',
    precision: 7,
    isInverted: true
  },
  'JPY/NZD': {
    pair: 'JPY/NZD',
    ccy1: 'JPY',
    ccy2: 'NZD',
    precision: 7,
    isInverted: true
  },
  'JPY/PLN': {
    pair: 'JPY/PLN',
    ccy1: 'JPY',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true
  },
  'JPY/QAR': {
    pair: 'JPY/QAR',
    ccy1: 'JPY',
    ccy2: 'QAR',
    precision: 5,
    isInverted: false
  },
  'JPY/RON': {
    pair: 'JPY/RON',
    ccy1: 'JPY',
    ccy2: 'RON',
    precision: 5,
    isInverted: false
  },
  'JPY/RSD': {
    pair: 'JPY/RSD',
    ccy1: 'JPY',
    ccy2: 'RSD',
    precision: 5,
    isInverted: false
  },
  'JPY/SAR': {
    pair: 'JPY/SAR',
    ccy1: 'JPY',
    ccy2: 'SAR',
    precision: 5,
    isInverted: false
  },
  'JPY/SEK': {
    pair: 'JPY/SEK',
    ccy1: 'JPY',
    ccy2: 'SEK',
    precision: 5,
    isInverted: true
  },
  'JPY/SGD': {
    pair: 'JPY/SGD',
    ccy1: 'JPY',
    ccy2: 'SGD',
    precision: 7,
    isInverted: true
  },
  'JPY/THB': {
    pair: 'JPY/THB',
    ccy1: 'JPY',
    ccy2: 'THB',
    precision: 5,
    isInverted: true
  },
  'JPY/TND': {
    pair: 'JPY/TND',
    ccy1: 'JPY',
    ccy2: 'TND',
    precision: 5,
    isInverted: false
  },
  'JPY/TRY': {
    pair: 'JPY/TRY',
    ccy1: 'JPY',
    ccy2: 'TRY',
    precision: 7,
    isInverted: true
  },
  'JPY/USD': {
    pair: 'JPY/USD',
    ccy1: 'JPY',
    ccy2: 'USD',
    precision: 7,
    isInverted: true
  },
  'JPY/ZAR': {
    pair: 'JPY/ZAR',
    ccy1: 'JPY',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: true
  },
  'KES/AUD': {
    pair: 'KES/AUD',
    ccy1: 'KES',
    ccy2: 'AUD',
    precision: 4,
    isInverted: true
  },
  'KES/EUR': {
    pair: 'KES/EUR',
    ccy1: 'KES',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true
  },
  'KES/GBP': {
    pair: 'KES/GBP',
    ccy1: 'KES',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'KES/JPY': {
    pair: 'KES/JPY',
    ccy1: 'KES',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true
  },
  'KWD/CAD': {
    pair: 'KWD/CAD',
    ccy1: 'KWD',
    ccy2: 'CAD',
    precision: 6,
    isInverted: true
  },
  'KWD/CHF': {
    pair: 'KWD/CHF',
    ccy1: 'KWD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'KWD/JPY': {
    pair: 'KWD/JPY',
    ccy1: 'KWD',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true
  },
  'KWD/USD': {
    pair: 'KWD/USD',
    ccy1: 'KWD',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'MAD/CHF': {
    pair: 'MAD/CHF',
    ccy1: 'MAD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'MAD/EUR': {
    pair: 'MAD/EUR',
    ccy1: 'MAD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'MAD/GBP': {
    pair: 'MAD/GBP',
    ccy1: 'MAD',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'MAD/JPY': {
    pair: 'MAD/JPY',
    ccy1: 'MAD',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true
  },
  'MAD/SEK': {
    pair: 'MAD/SEK',
    ccy1: 'MAD',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false
  },
  'MAD/USD': {
    pair: 'MAD/USD',
    ccy1: 'MAD',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'MUR/EUR': {
    pair: 'MUR/EUR',
    ccy1: 'MUR',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true
  },
  'MUR/USD': {
    pair: 'MUR/USD',
    ccy1: 'MUR',
    ccy2: 'USD',
    precision: 7,
    isInverted: true
  },
  'MXN/CAD': {
    pair: 'MXN/CAD',
    ccy1: 'MXN',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true
  },
  'MXN/CHF': {
    pair: 'MXN/CHF',
    ccy1: 'MXN',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'MXN/CNH': {
    pair: 'MXN/CNH',
    ccy1: 'MXN',
    ccy2: 'CNH',
    precision: 4,
    isInverted: true
  },
  'MXN/HKD': {
    pair: 'MXN/HKD',
    ccy1: 'MXN',
    ccy2: 'HKD',
    precision: 4,
    isInverted: false
  },
  'MXN/EUR': {
    pair: 'MXN/EUR',
    ccy1: 'MXN',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'MXN/JPY': {
    pair: 'MXN/JPY',
    ccy1: 'MXN',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false
  },
  'MXN/PLN': {
    pair: 'MXN/PLN',
    ccy1: 'MXN',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true
  },
  'MXN/TRY': {
    pair: 'MXN/TRY',
    ccy1: 'MXN',
    ccy2: 'TRY',
    precision: 5,
    isInverted: true
  },
  'MXN/ZAR': {
    pair: 'MXN/ZAR',
    ccy1: 'MXN',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: true
  },
  'NOK/AUD': {
    pair: 'NOK/AUD',
    ccy1: 'NOK',
    ccy2: 'AUD',
    precision: 7,
    isInverted: true
  },
  'NOK/CAD': {
    pair: 'NOK/CAD',
    ccy1: 'NOK',
    ccy2: 'CAD',
    precision: 7,
    isInverted: true
  },
  'NOK/CHF': {
    pair: 'NOK/CHF',
    ccy1: 'NOK',
    ccy2: 'CHF',
    precision: 7,
    isInverted: true
  },
  'NOK/CNH': {
    pair: 'NOK/CNH',
    ccy1: 'NOK',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false
  },
  'NOK/CZK': {
    pair: 'NOK/CZK',
    ccy1: 'NOK',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false
  },
  'NOK/DKK': {
    pair: 'NOK/DKK',
    ccy1: 'NOK',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false
  },
  'NOK/EUR': {
    pair: 'NOK/EUR',
    ccy1: 'NOK',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true
  },
  'NOK/GBP': {
    pair: 'NOK/GBP',
    ccy1: 'NOK',
    ccy2: 'GBP',
    precision: 7,
    isInverted: true
  },
  'NOK/HKD': {
    pair: 'NOK/HKD',
    ccy1: 'NOK',
    ccy2: 'HKD',
    precision: 6,
    isInverted: false
  },
  'NOK/HUF': {
    pair: 'NOK/HUF',
    ccy1: 'NOK',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false
  },
  'NOK/JPY': {
    pair: 'NOK/JPY',
    ccy1: 'NOK',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false
  },
  'NOK/NZD': {
    pair: 'NOK/NZD',
    ccy1: 'NOK',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true
  },
  'NOK/PLN': {
    pair: 'NOK/PLN',
    ccy1: 'NOK',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false
  },
  'NOK/SEK': {
    pair: 'NOK/SEK',
    ccy1: 'NOK',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false
  },
  'NOK/SGD': {
    pair: 'NOK/SGD',
    ccy1: 'NOK',
    ccy2: 'SGD',
    precision: 5,
    isInverted: true
  },
  'NOK/TRY': {
    pair: 'NOK/TRY',
    ccy1: 'NOK',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false
  },
  'NOK/USD': {
    pair: 'NOK/USD',
    ccy1: 'NOK',
    ccy2: 'USD',
    precision: 7,
    isInverted: true
  },
  'NZD/AUD': {
    pair: 'NZD/AUD',
    ccy1: 'NZD',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true
  },
  'NZD/CAD': {
    pair: 'NZD/CAD',
    ccy1: 'NZD',
    ccy2: 'CAD',
    precision: 5,
    isInverted: false
  },
  'NZD/CHF': {
    pair: 'NZD/CHF',
    ccy1: 'NZD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false
  },
  'NZD/CNH': {
    pair: 'NZD/CNH',
    ccy1: 'NZD',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false
  },
  'NZD/CZK': {
    pair: 'NZD/CZK',
    ccy1: 'NZD',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false
  },
  'NZD/DKK': {
    pair: 'NZD/DKK',
    ccy1: 'NZD',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false
  },
  'NZD/EUR': {
    pair: 'NZD/EUR',
    ccy1: 'NZD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'NZD/GBP': {
    pair: 'NZD/GBP',
    ccy1: 'NZD',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'NZD/HKD': {
    pair: 'NZD/HKD',
    ccy1: 'NZD',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false
  },
  'NZD/HUF': {
    pair: 'NZD/HUF',
    ccy1: 'NZD',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false
  },
  'NZD/ILS': {
    pair: 'NZD/ILS',
    ccy1: 'NZD',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false
  },
  'NZD/JPY': {
    pair: 'NZD/JPY',
    ccy1: 'NZD',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false
  },
  'NZD/NOK': {
    pair: 'NZD/NOK',
    ccy1: 'NZD',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false
  },
  'NZD/PLN': {
    pair: 'NZD/PLN',
    ccy1: 'NZD',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false
  },
  'NZD/SEK': {
    pair: 'NZD/SEK',
    ccy1: 'NZD',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false
  },
  'NZD/SGD': {
    pair: 'NZD/SGD',
    ccy1: 'NZD',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false
  },
  'NZD/TRY': {
    pair: 'NZD/TRY',
    ccy1: 'NZD',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false
  },
  'NZD/USD': {
    pair: 'NZD/USD',
    ccy1: 'NZD',
    ccy2: 'USD',
    precision: 5,
    isInverted: false
  },
  'OMR/EUR': {
    pair: 'OMR/EUR',
    ccy1: 'OMR',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'OMR/USD': {
    pair: 'OMR/USD',
    ccy1: 'OMR',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'PLN/AED': {
    pair: 'PLN/AED',
    ccy1: 'PLN',
    ccy2: 'AED',
    precision: 5,
    isInverted: true
  },
  'PLN/AUD': {
    pair: 'PLN/AUD',
    ccy1: 'PLN',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true
  },
  'PLN/BGN': {
    pair: 'PLN/BGN',
    ccy1: 'PLN',
    ccy2: 'BGN',
    precision: 5,
    isInverted: true
  },
  'PLN/CHF': {
    pair: 'PLN/CHF',
    ccy1: 'PLN',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'PLN/CNH': {
    pair: 'PLN/CNH',
    ccy1: 'PLN',
    ccy2: 'CNH',
    precision: 4,
    isInverted: false
  },
  'PLN/CZK': {
    pair: 'PLN/CZK',
    ccy1: 'PLN',
    ccy2: 'CZK',
    precision: 5,
    isInverted: true
  },
  'PLN/DKK': {
    pair: 'PLN/DKK',
    ccy1: 'PLN',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false
  },
  'PLN/EUR': {
    pair: 'PLN/EUR',
    ccy1: 'PLN',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'PLN/GBP': {
    pair: 'PLN/GBP',
    ccy1: 'PLN',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'PLN/HKD': {
    pair: 'PLN/HKD',
    ccy1: 'PLN',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false
  },
  'PLN/HRK': {
    pair: 'PLN/HRK',
    ccy1: 'PLN',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false
  },
  'PLN/HUF': {
    pair: 'PLN/HUF',
    ccy1: 'PLN',
    ccy2: 'HUF',
    precision: 3,
    isInverted: false
  },
  'PLN/ILS': {
    pair: 'PLN/ILS',
    ccy1: 'PLN',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false
  },
  'PLN/JPY': {
    pair: 'PLN/JPY',
    ccy1: 'PLN',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false
  },
  'PLN/MXN': {
    pair: 'PLN/MXN',
    ccy1: 'PLN',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false
  },
  'PLN/RON': {
    pair: 'PLN/RON',
    ccy1: 'PLN',
    ccy2: 'RON',
    precision: 5,
    isInverted: false
  },
  'PLN/SEK': {
    pair: 'PLN/SEK',
    ccy1: 'PLN',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false
  },
  'PLN/SGD': {
    pair: 'PLN/SGD',
    ccy1: 'PLN',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false
  },
  'PLN/THB': {
    pair: 'PLN/THB',
    ccy1: 'PLN',
    ccy2: 'THB',
    precision: 5,
    isInverted: true
  },
  'PLN/TND': {
    pair: 'PLN/TND',
    ccy1: 'PLN',
    ccy2: 'TND',
    precision: 4,
    isInverted: true
  },
  'PLN/TRY': {
    pair: 'PLN/TRY',
    ccy1: 'PLN',
    ccy2: 'TRY',
    precision: 5,
    isInverted: true
  },
  'PLN/USD': {
    pair: 'PLN/USD',
    ccy1: 'PLN',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'PLN/ZAR': {
    pair: 'PLN/ZAR',
    ccy1: 'PLN',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false
  },
  'QAR/CHF': {
    pair: 'QAR/CHF',
    ccy1: 'QAR',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'QAR/JPY': {
    pair: 'QAR/JPY',
    ccy1: 'QAR',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true
  },
  'QAR/USD': {
    pair: 'QAR/USD',
    ccy1: 'QAR',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'RON/BGN': {
    pair: 'RON/BGN',
    ccy1: 'RON',
    ccy2: 'BGN',
    precision: 5,
    isInverted: true
  },
  'RON/CAD': {
    pair: 'RON/CAD',
    ccy1: 'RON',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true
  },
  'RON/CHF': {
    pair: 'RON/CHF',
    ccy1: 'RON',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'RON/CNH': {
    pair: 'RON/CNH',
    ccy1: 'RON',
    ccy2: 'CNH',
    precision: 4,
    isInverted: false
  },
  'RON/CZK': {
    pair: 'RON/CZK',
    ccy1: 'RON',
    ccy2: 'CZK',
    precision: 5,
    isInverted: true
  },
  'RON/EUR': {
    pair: 'RON/EUR',
    ccy1: 'RON',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'RON/HUF': {
    pair: 'RON/HUF',
    ccy1: 'RON',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true
  },
  'RON/JPY': {
    pair: 'RON/JPY',
    ccy1: 'RON',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true
  },
  'RON/PLN': {
    pair: 'RON/PLN',
    ccy1: 'RON',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true
  },
  'RON/USD': {
    pair: 'RON/USD',
    ccy1: 'RON',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'RON/ZAR': {
    pair: 'RON/ZAR',
    ccy1: 'RON',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false
  },
  'RSD/CHF': {
    pair: 'RSD/CHF',
    ccy1: 'RSD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'RSD/JPY': {
    pair: 'RSD/JPY',
    ccy1: 'RSD',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true
  },
  'SAR/AED': {
    pair: 'SAR/AED',
    ccy1: 'SAR',
    ccy2: 'AED',
    precision: 5,
    isInverted: false
  },
  'SAR/CAD': {
    pair: 'SAR/CAD',
    ccy1: 'SAR',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true
  },
  'SAR/CHF': {
    pair: 'SAR/CHF',
    ccy1: 'SAR',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false
  },
  'SAR/CZK': {
    pair: 'SAR/CZK',
    ccy1: 'SAR',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false
  },
  'SAR/EUR': {
    pair: 'SAR/EUR',
    ccy1: 'SAR',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'SAR/GBP': {
    pair: 'SAR/GBP',
    ccy1: 'SAR',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'SAR/JPY': {
    pair: 'SAR/JPY',
    ccy1: 'SAR',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true
  },
  'SAR/USD': {
    pair: 'SAR/USD',
    ccy1: 'SAR',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'SEK/AED': {
    pair: 'SEK/AED',
    ccy1: 'SEK',
    ccy2: 'AED',
    precision: 4,
    isInverted: true
  },
  'SEK/AUD': {
    pair: 'SEK/AUD',
    ccy1: 'SEK',
    ccy2: 'AUD',
    precision: 7,
    isInverted: true
  },
  'SEK/CAD': {
    pair: 'SEK/CAD',
    ccy1: 'SEK',
    ccy2: 'CAD',
    precision: 7,
    isInverted: true
  },
  'SEK/CHF': {
    pair: 'SEK/CHF',
    ccy1: 'SEK',
    ccy2: 'CHF',
    precision: 7,
    isInverted: true
  },
  'SEK/CNH': {
    pair: 'SEK/CNH',
    ccy1: 'SEK',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false
  },
  'SEK/CZK': {
    pair: 'SEK/CZK',
    ccy1: 'SEK',
    ccy2: 'CZK',
    precision: 4,
    isInverted: true
  },
  'SEK/DKK': {
    pair: 'SEK/DKK',
    ccy1: 'SEK',
    ccy2: 'DKK',
    precision: 6,
    isInverted: true
  },
  'SEK/EUR': {
    pair: 'SEK/EUR',
    ccy1: 'SEK',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true
  },
  'SEK/GBP': {
    pair: 'SEK/GBP',
    ccy1: 'SEK',
    ccy2: 'GBP',
    precision: 7,
    isInverted: true
  },
  'SEK/HKD': {
    pair: 'SEK/HKD',
    ccy1: 'SEK',
    ccy2: 'HKD',
    precision: 6,
    isInverted: true
  },
  'SEK/HUF': {
    pair: 'SEK/HUF',
    ccy1: 'SEK',
    ccy2: 'HUF',
    precision: 3,
    isInverted: false
  },
  'SEK/JPY': {
    pair: 'SEK/JPY',
    ccy1: 'SEK',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false
  },
  'SEK/MAD': {
    pair: 'SEK/MAD',
    ccy1: 'SEK',
    ccy2: 'MAD',
    precision: 5,
    isInverted: true
  },
  'SEK/NOK': {
    pair: 'SEK/NOK',
    ccy1: 'SEK',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true
  },
  'SEK/NZD': {
    pair: 'SEK/NZD',
    ccy1: 'SEK',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true
  },
  'SEK/PLN': {
    pair: 'SEK/PLN',
    ccy1: 'SEK',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false
  },
  'SEK/RON': {
    pair: 'SEK/RON',
    ccy1: 'SEK',
    ccy2: 'RON',
    precision: 5,
    isInverted: false
  },
  'SEK/SGD': {
    pair: 'SEK/SGD',
    ccy1: 'SEK',
    ccy2: 'SGD',
    precision: 5,
    isInverted: true
  },
  'SEK/THB': {
    pair: 'SEK/THB',
    ccy1: 'SEK',
    ccy2: 'THB',
    precision: 5,
    isInverted: false
  },
  'SEK/TRY': {
    pair: 'SEK/TRY',
    ccy1: 'SEK',
    ccy2: 'TRY',
    precision: 5,
    isInverted: true
  },
  'SEK/USD': {
    pair: 'SEK/USD',
    ccy1: 'SEK',
    ccy2: 'USD',
    precision: 7,
    isInverted: true
  },
  'SEK/ZAR': {
    pair: 'SEK/ZAR',
    ccy1: 'SEK',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: true
  },
  'SGD/AED': {
    pair: 'SGD/AED',
    ccy1: 'SGD',
    ccy2: 'AED',
    precision: 5,
    isInverted: false
  },
  'SGD/AUD': {
    pair: 'SGD/AUD',
    ccy1: 'SGD',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true
  },
  'SGD/CAD': {
    pair: 'SGD/CAD',
    ccy1: 'SGD',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true
  },
  'SGD/CHF': {
    pair: 'SGD/CHF',
    ccy1: 'SGD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'SGD/CNH': {
    pair: 'SGD/CNH',
    ccy1: 'SGD',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false
  },
  'SGD/CZK': {
    pair: 'SGD/CZK',
    ccy1: 'SGD',
    ccy2: 'CZK',
    precision: 5,
    isInverted: true
  },
  'SGD/DKK': {
    pair: 'SGD/DKK',
    ccy1: 'SGD',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true
  },
  'SGD/EUR': {
    pair: 'SGD/EUR',
    ccy1: 'SGD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'SGD/GBP': {
    pair: 'SGD/GBP',
    ccy1: 'SGD',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'SGD/HKD': {
    pair: 'SGD/HKD',
    ccy1: 'SGD',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false
  },
  'SGD/HUF': {
    pair: 'SGD/HUF',
    ccy1: 'SGD',
    ccy2: 'HUF',
    precision: 5,
    isInverted: false
  },
  'SGD/JPY': {
    pair: 'SGD/JPY',
    ccy1: 'SGD',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false
  },
  'SGD/NOK': {
    pair: 'SGD/NOK',
    ccy1: 'SGD',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false
  },
  'SGD/NZD': {
    pair: 'SGD/NZD',
    ccy1: 'SGD',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true
  },
  'SGD/RUB': {
    pair: 'SGD/RUB',
    ccy1: 'SGD',
    ccy2: 'RUB',
    precision: 4,
    isInverted: true
  },
  'SGD/PLN': {
    pair: 'SGD/PLN',
    ccy1: 'SGD',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true
  },
  'SGD/SEK': {
    pair: 'SGD/SEK',
    ccy1: 'SGD',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false
  },
  'SGD/THB': {
    pair: 'SGD/THB',
    ccy1: 'SGD',
    ccy2: 'THB',
    precision: 5,
    isInverted: false
  },
  'SGD/USD': {
    pair: 'SGD/USD',
    ccy1: 'SGD',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'SGD/ZAR': {
    pair: 'SGD/ZAR',
    ccy1: 'SGD',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: true
  },
  'THB/CAD': {
    pair: 'THB/CAD',
    ccy1: 'THB',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true
  },
  'THB/CHF': {
    pair: 'THB/CHF',
    ccy1: 'THB',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'THB/CNH': {
    pair: 'THB/CNH',
    ccy1: 'THB',
    ccy2: 'CNH',
    precision: 6,
    isInverted: false
  },
  'THB/EUR': {
    pair: 'THB/EUR',
    ccy1: 'THB',
    ccy2: 'EUR',
    precision: 7,
    isInverted: true
  },
  'THB/HKD': {
    pair: 'THB/HKD',
    ccy1: 'THB',
    ccy2: 'HKD',
    precision: 5,
    isInverted: true
  },
  'THB/JPY': {
    pair: 'THB/JPY',
    ccy1: 'THB',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false
  },
  'THB/PLN': {
    pair: 'THB/PLN',
    ccy1: 'THB',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false
  },
  'THB/USD': {
    pair: 'THB/USD',
    ccy1: 'THB',
    ccy2: 'USD',
    precision: 7,
    isInverted: true
  },
  'TND/CHF': {
    pair: 'TND/CHF',
    ccy1: 'TND',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false
  },
  'TND/EUR': {
    pair: 'TND/EUR',
    ccy1: 'TND',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'TND/GBP': {
    pair: 'TND/GBP',
    ccy1: 'TND',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'TND/JPY': {
    pair: 'TND/JPY',
    ccy1: 'TND',
    ccy2: 'JPY',
    precision: 5,
    isInverted: true
  },
  'TND/PLN': {
    pair: 'TND/PLN',
    ccy1: 'TND',
    ccy2: 'PLN',
    precision: 4,
    isInverted: false
  },
  'TND/USD': {
    pair: 'TND/USD',
    ccy1: 'TND',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'TRY/AUD': {
    pair: 'TRY/AUD',
    ccy1: 'TRY',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true
  },
  'TRY/CAD': {
    pair: 'TRY/CAD',
    ccy1: 'TRY',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true
  },
  'TRY/CHF': {
    pair: 'TRY/CHF',
    ccy1: 'TRY',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'TRY/CZK': {
    pair: 'TRY/CZK',
    ccy1: 'TRY',
    ccy2: 'CZK',
    precision: 5,
    isInverted: false
  },
  'TRY/DKK': {
    pair: 'TRY/DKK',
    ccy1: 'TRY',
    ccy2: 'DKK',
    precision: 5,
    isInverted: true
  },
  'TRY/EUR': {
    pair: 'TRY/EUR',
    ccy1: 'TRY',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'TRY/GBP': {
    pair: 'TRY/GBP',
    ccy1: 'TRY',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'TRY/HUF': {
    pair: 'TRY/HUF',
    ccy1: 'TRY',
    ccy2: 'HUF',
    precision: 3,
    isInverted: false
  },
  'TRY/ILS': {
    pair: 'TRY/ILS',
    ccy1: 'TRY',
    ccy2: 'ILS',
    precision: 4,
    isInverted: false
  },
  'TRY/JPY': {
    pair: 'TRY/JPY',
    ccy1: 'TRY',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false
  },
  'TRY/MXN': {
    pair: 'TRY/MXN',
    ccy1: 'TRY',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false
  },
  'TRY/NOK': {
    pair: 'TRY/NOK',
    ccy1: 'TRY',
    ccy2: 'NOK',
    precision: 5,
    isInverted: true
  },
  'TRY/NZD': {
    pair: 'TRY/NZD',
    ccy1: 'TRY',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true
  },
  'TRY/PLN': {
    pair: 'TRY/PLN',
    ccy1: 'TRY',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false
  },
  'TRY/SEK': {
    pair: 'TRY/SEK',
    ccy1: 'TRY',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false
  },
  'TRY/USD': {
    pair: 'TRY/USD',
    ccy1: 'TRY',
    ccy2: 'USD',
    precision: 5,
    isInverted: true
  },
  'TRY/ZAR': {
    pair: 'TRY/ZAR',
    ccy1: 'TRY',
    ccy2: 'ZAR',
    precision: 5,
    isInverted: false
  },
  'USD/AED': {
    pair: 'USD/AED',
    ccy1: 'USD',
    ccy2: 'AED',
    precision: 5,
    isInverted: false
  },
  'USD/AUD': {
    pair: 'USD/AUD',
    ccy1: 'USD',
    ccy2: 'AUD',
    precision: 5,
    isInverted: true
  },
  'USD/BGN': {
    pair: 'USD/BGN',
    ccy1: 'USD',
    ccy2: 'BGN',
    precision: 5,
    isInverted: false
  },
  'USD/BHD': {
    pair: 'USD/BHD',
    ccy1: 'USD',
    ccy2: 'BHD',
    precision: 6,
    isInverted: false
  },
  'USD/BRO': {
    pair: 'USD/BRO',
    ccy1: 'USD',
    ccy2: 'BRO',
    precision: 5,
    isInverted: false
  },
  'USD/BWP': {
    pair: 'USD/BWP',
    ccy1: 'USD',
    ccy2: 'BWP',
    precision: 5,
    isInverted: false
  },
  'USD/CAD': {
    pair: 'USD/CAD',
    ccy1: 'USD',
    ccy2: 'CAD',
    precision: 5,
    isInverted: false
  },
  'USD/CHF': {
    pair: 'USD/CHF',
    ccy1: 'USD',
    ccy2: 'CHF',
    precision: 5,
    isInverted: false
  },
  'USD/CNH': {
    pair: 'USD/CNH',
    ccy1: 'USD',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false
  },
  'USD/CZK': {
    pair: 'USD/CZK',
    ccy1: 'USD',
    ccy2: 'CZK',
    precision: 4,
    isInverted: false
  },
  'USD/DKK': {
    pair: 'USD/DKK',
    ccy1: 'USD',
    ccy2: 'DKK',
    precision: 5,
    isInverted: false
  },
  'USD/EUR': {
    pair: 'USD/EUR',
    ccy1: 'USD',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'USD/GBP': {
    pair: 'USD/GBP',
    ccy1: 'USD',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'USD/HKD': {
    pair: 'USD/HKD',
    ccy1: 'USD',
    ccy2: 'HKD',
    precision: 5,
    isInverted: false
  },
  'USD/HRK': {
    pair: 'USD/HRK',
    ccy1: 'USD',
    ccy2: 'HRK',
    precision: 5,
    isInverted: false
  },
  'USD/HUF': {
    pair: 'USD/HUF',
    ccy1: 'USD',
    ccy2: 'HUF',
    precision: 3,
    isInverted: false
  },
  'USD/ILS': {
    pair: 'USD/ILS',
    ccy1: 'USD',
    ccy2: 'ILS',
    precision: 5,
    isInverted: false
  },
  'USD/ISK': {
    pair: 'USD/ISK',
    ccy1: 'USD',
    ccy2: 'ISK',
    precision: 3,
    isInverted: false
  },
  'USD/JOD': {
    pair: 'USD/JOD',
    ccy1: 'USD',
    ccy2: 'JOD',
    precision: 5,
    isInverted: false
  },
  'USD/JPY': {
    pair: 'USD/JPY',
    ccy1: 'USD',
    ccy2: 'JPY',
    precision: 3,
    isInverted: false
  },
  'USD/KWD': {
    pair: 'USD/KWD',
    ccy1: 'USD',
    ccy2: 'KWD',
    precision: 6,
    isInverted: false
  },
  'USD/MAD': {
    pair: 'USD/MAD',
    ccy1: 'USD',
    ccy2: 'MAD',
    precision: 5,
    isInverted: false
  },
  'USD/MUR': {
    pair: 'USD/MUR',
    ccy1: 'USD',
    ccy2: 'MUR',
    precision: 3,
    isInverted: false
  },
  'USD/MXN': {
    pair: 'USD/MXN',
    ccy1: 'USD',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false
  },
  'USD/MZN': {
    pair: 'USD/MZN',
    ccy1: 'USD',
    ccy2: 'MZN',
    precision: 2,
    isInverted: false
  },
  'USD/NOK': {
    pair: 'USD/NOK',
    ccy1: 'USD',
    ccy2: 'NOK',
    precision: 5,
    isInverted: false
  },
  'USD/NZD': {
    pair: 'USD/NZD',
    ccy1: 'USD',
    ccy2: 'NZD',
    precision: 5,
    isInverted: true
  },
  'USD/OMR': {
    pair: 'USD/OMR',
    ccy1: 'USD',
    ccy2: 'OMR',
    precision: 5,
    isInverted: false
  },
  'USD/PLN': {
    pair: 'USD/PLN',
    ccy1: 'USD',
    ccy2: 'PLN',
    precision: 5,
    isInverted: false
  },
  'USD/QAR': {
    pair: 'USD/QAR',
    ccy1: 'USD',
    ccy2: 'QAR',
    precision: 5,
    isInverted: false
  },
  'USD/RON': {
    pair: 'USD/RON',
    ccy1: 'USD',
    ccy2: 'RON',
    precision: 5,
    isInverted: false
  },
  'USD/RSD': {
    pair: 'USD/RSD',
    ccy1: 'USD',
    ccy2: 'RSD',
    precision: 4,
    isInverted: false
  },
  'USD/SAR': {
    pair: 'USD/SAR',
    ccy1: 'USD',
    ccy2: 'SAR',
    precision: 5,
    isInverted: false
  },
  'USD/SEK': {
    pair: 'USD/SEK',
    ccy1: 'USD',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false
  },
  'USD/SGD': {
    pair: 'USD/SGD',
    ccy1: 'USD',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false
  },
  'USD/THB': {
    pair: 'USD/THB',
    ccy1: 'USD',
    ccy2: 'THB',
    precision: 3,
    isInverted: false
  },
  'USD/TND': {
    pair: 'USD/TND',
    ccy1: 'USD',
    ccy2: 'TND',
    precision: 5,
    isInverted: false
  },
  'USD/TRY': {
    pair: 'USD/TRY',
    ccy1: 'USD',
    ccy2: 'TRY',
    precision: 5,
    isInverted: false
  },
  'USD/XAF': {
    pair: 'USD/XAF',
    ccy1: 'USD',
    ccy2: 'XAF',
    precision: 4,
    isInverted: false
  },
  'USD/XOF': {
    pair: 'USD/XOF',
    ccy1: 'USD',
    ccy2: 'XOF',
    precision: 4,
    isInverted: false
  },
  'ZAR/CAD': {
    pair: 'ZAR/CAD',
    ccy1: 'ZAR',
    ccy2: 'CAD',
    precision: 5,
    isInverted: true
  },
  'ZAR/CHF': {
    pair: 'ZAR/CHF',
    ccy1: 'ZAR',
    ccy2: 'CHF',
    precision: 5,
    isInverted: true
  },
  'ZAR/CNH': {
    pair: 'ZAR/CNH',
    ccy1: 'ZAR',
    ccy2: 'CNH',
    precision: 5,
    isInverted: false
  },
  'ZAR/CZK': {
    pair: 'ZAR/CZK',
    ccy1: 'ZAR',
    ccy2: 'CZK',
    precision: 5,
    isInverted: true
  },
  'ZAR/EUR': {
    pair: 'ZAR/EUR',
    ccy1: 'ZAR',
    ccy2: 'EUR',
    precision: 5,
    isInverted: true
  },
  'ZAR/GBP': {
    pair: 'ZAR/GBP',
    ccy1: 'ZAR',
    ccy2: 'GBP',
    precision: 5,
    isInverted: true
  },
  'ZAR/HUF': {
    pair: 'ZAR/HUF',
    ccy1: 'ZAR',
    ccy2: 'HUF',
    precision: 5,
    isInverted: true
  },
  'ZAR/JPY': {
    pair: 'ZAR/JPY',
    ccy1: 'ZAR',
    ccy2: 'JPY',
    precision: 5,
    isInverted: false
  },
  'ZAR/MXN': {
    pair: 'ZAR/MXN',
    ccy1: 'ZAR',
    ccy2: 'MXN',
    precision: 5,
    isInverted: false
  },
  'ZAR/PLN': {
    pair: 'ZAR/PLN',
    ccy1: 'ZAR',
    ccy2: 'PLN',
    precision: 5,
    isInverted: true
  },
  'ZAR/RON': {
    pair: 'ZAR/RON',
    ccy1: 'ZAR',
    ccy2: 'RON',
    precision: 5,
    isInverted: true
  },
  'ZAR/SEK': {
    pair: 'ZAR/SEK',
    ccy1: 'ZAR',
    ccy2: 'SEK',
    precision: 5,
    isInverted: false
  },
  'ZAR/SGD': {
    pair: 'ZAR/SGD',
    ccy1: 'ZAR',
    ccy2: 'SGD',
    precision: 5,
    isInverted: false
  },
  'ZAR/TRY': {
    pair: 'ZAR/TRY',
    ccy1: 'ZAR',
    ccy2: 'TRY',
    precision: 5,
    isInverted: true
  }
};
